import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        about: file(relativePath: { eq: "headshots/Cory Iffert Headshot.jpg" }) {
          childImageSharp {
            fixed(width: 250, height: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => (
      <Img
        style={{ borderRadius: '50%', marginTop: '10px' }}
        fixed={data.about.childImageSharp.fixed}
      />
    )}
  />
);

export default About;
