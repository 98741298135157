import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { Row, Col } from 'react-bootstrap';
import { MDBCard, MDBCardBody, MDBIcon } from 'mdbreact';
import { Link } from 'gatsby';
import Layout from '../components/Layout/Layout';
import Title from '../components/Title/Title';
import SEO from '../components/SEO/SEO';
import { headData } from '../mock/data';
import About from '../components/Image/About';
import IffertMediaEmilyPries from '../components/Image/IffertMediaEmilyPries';
import IffertMediaChristianOseguera from '../components/Image/IffertMediaChristianOseguera';
import IffertMediaMikaelaKosik from '../components/Image/IffertMediaMikaelaKosik';
import IffertMediaJoeMunster from '../components/Image/IffertMediaJoeMunster';
import IffertMediaAbbyHirt from '../components/Image/IffertMediaAbbyHirt';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';
import TestimonialLogo from '../components/Image/TestimonialLogo';

const StyledTitle = styled(Title)`
  font-size: 18px;
  margin-top: 5rem;
`;

const SubTitle = styled.h3`
  font-size: 24px;
  margin: 2rem 0;
  font-weight: bold;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    margin-left: ${(props) => (props.marginleft ? '10px' : '0px')};
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const JobTitle = styled.h2`
  margin: 0 0 10px;
  color: #000;
  font-size: 14px;
  text-align: center;
`;

const Name = styled.h1`
  margin-top: 8px;
  font-size: 18px;
  color: #000;
  text-align: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => (props.width ? '90%' : '100%')};
  margin-right: ${(props) => props.marginright};
  justify-content: ${(props) => props.justifycontent};
  color: ${(props) => (props.color ? '#fff' : '#000')};
  @media (min-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const StaffColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  gap: 16px;
  @media (max-width: 1000px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: ${(props) => props.margin};
    margin-right: 0px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    margin-right: 0px;
  }
`;

const Icons = styled.i`
  color: #fff;
  margin: 20px 0;
  transition: all 0.2s ease-in;
  &:hover {
    transform: translateY(-2px);
  }
  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

const IffertTeam = () => {
  return (
    <Grid>
      <StaffColumn>
        <LogoWrapper marginleft>
          <About />
        </LogoWrapper>

        <Column color width justifycontent="flex-end">
          <Name>Cory Iffert</Name>
          <JobTitle>Owner & CEO</JobTitle>
        </Column>
      </StaffColumn>
      <StaffColumn>
        <LogoWrapper marginleft>
          <IffertMediaEmilyPries />
        </LogoWrapper>

        <Column color width justifycontent="flex-end">
          <Name>Emily Pries</Name>
          <JobTitle>Senior Account Manager</JobTitle>
        </Column>
      </StaffColumn>
      <StaffColumn>
        <LogoWrapper marginleft>
          <IffertMediaChristianOseguera />
        </LogoWrapper>

        <Column color width justifycontent="flex-end">
          <Name>Christian Oseguera</Name>
          <JobTitle>Account Manager</JobTitle>
        </Column>
      </StaffColumn>
      <StaffColumn>
        <LogoWrapper marginleft>
          <IffertMediaMikaelaKosik />
        </LogoWrapper>

        <Column color width justifycontent="flex-end">
          <Name>Mikaela Kosik</Name>
          <JobTitle>Account Manager</JobTitle>
        </Column>
      </StaffColumn>
      <StaffColumn>
        <LogoWrapper marginleft>
          <IffertMediaJoeMunster />
        </LogoWrapper>

        <Column color width justifycontent="flex-end">
          <Name>Joe Munster</Name>
          <JobTitle>Digital Marketing Coordinator</JobTitle>
        </Column>
      </StaffColumn>
      <StaffColumn>
        <LogoWrapper marginleft>
          <IffertMediaAbbyHirt />
        </LogoWrapper>

        <Column color width justifycontent="flex-end">
          <Name>Abby Hirt</Name>
          <JobTitle>Content & Email Coordinator</JobTitle>
        </Column>
      </StaffColumn>
    </Grid>
  );
};

const AboutPage = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true);
  const { image } = headData;
  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
    setLoading(false);
  }, []);

  if (loading) return null;

  return (
    <>
      <Layout paddingbottom buttoncolor border backgroundcolor color>
        <Helmet lang="en" />
        <SEO
          title="Iffert Media | A Nashville Marketing Agency"
          siteUrl="https://www.iffertmedia.com/about-iffertmedia"
          image={image}
          description="Learn why the Iffert Media team is best equipped to create more leads and generate more sales for your business through social media marketing and advertising."
        />
        <Title display fontsize title="Welcome to Iffert Media" />
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <IffertTeam />
        </Fade>
        <Row className="about-wrapper" style={{ marginTop: '10rem' }}>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <StyledTitle title="Working with you to grow your business" />
              <Column>
                <div className="about-wrapper__info">
                  <p className="about-wrapper__info-text">
                    Iffert Media is a social media and advertising marketing agency based in
                    Nashville, TN with awesome clients nationwide! Everyday, we're breaking the
                    stereotype of a traditional digital marketing agency.
                  </p>
                  <p className="about-wrapper__info-text">
                    We pride ourselves on affordable pricing, excellent service, and results. Want
                    to drive sales and get more leads? We've done it in every industry and will do
                    it for you, too!
                  </p>
                  <SubTitle>What's it like to partner with us? </SubTitle>
                  <p className="about-wrapper__info-text">
                    We build great relationships with our clients and work our fingers off to help
                    you reach your business goals. The onboarding process is quick and only takes a
                    couple clicks. Our effective strategy we've been testing for a long time is
                    proven to bring results, increasing leads and driving sales.
                  </p>
                  <p className="about-wrapper__info-text">
                    If you don't know where to start with social media marketing, digital
                    advertising, are wondering how to create and execute a marketing strategy, or
                    just need some marketing help and want someone to take over, give us a call!
                  </p>
                </div>
                <Link
                  to="/services"
                  className="cta-btn cta-btn--about-page"
                  style={{ color: '#000', width: '50%' }}
                >
                  Let's Grow Your Business
                </Link>
              </Column>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <MDBCard testimonial className="md-shadow">
                <LogoWrapper>
                  <TestimonialLogo />
                </LogoWrapper>
                <MDBCardBody style={{ marginTop: '15px' }}>
                  <h4 className="mb-4 font-weight-bold" style={{ textAlign: 'center' }}>
                    Jacob W.
                  </h4>
                  <h4 className="mb-4 font-weight-bold" style={{ textAlign: 'center' }}>
                    Edge Fitness Performance
                  </h4>
                  <hr />
                  <p className="mt-4 dark-grey-text">
                    <MDBIcon icon="quote-left" className="pr-2" />
                    Cory has been managing my facebook ads since the beginning of this year, and
                    we've noticed a steady and significant increase of online sales and traffic to
                    the website. Cory is professional, easy to work with, and knows what he's doing.
                    If you're trying to scale your business, I'd highly recommend using him!
                    <MDBIcon icon="quote-right" className="pr-2" />
                  </p>
                </MDBCardBody>
              </MDBCard>
              <MDBCard
                testimonial
                className="md-shadow"
                style={{ marginTop: '45px', backgroundColor: '#053d75', color: 'white' }}
              >
                <MDBCardBody
                  style={{
                    marginTop: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <h1>Follow us on social media</h1>
                  <div className="social-links-about">
                    <a rel="preconnect" href="https://www.facebook.com/iffertmedia/">
                      <Icons className="fa fa-facebook fa-2x" aria-hidden="true" />
                    </a>
                    <a
                      rel="preconnect"
                      href="https://www.instagram.com/iffertmedia/"
                      style={{ marginLeft: '40px' }}
                    >
                      <Icons className="fa fa-instagram fa-2x" aria-hidden="true" />
                    </a>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </Fade>
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default AboutPage;
// <GatsbySeo
//   title="Iffert Media | A Nashville Marketing Agency"
//   description="Learn why the Iffert Media team is best equipped to create more leads and generate more sales for your business through social media marketing and advertising."
//   canonical="https://www.iffertmedia.com/about-iffertmedia"
//   openGraph={{
//     url: 'https://www.iffertmedia.com/about-iffertmedia',
//     title: 'Iffert Media | About Our Nashville Social Media Marketing Agency',
//     description:
//       'Learn why the Iffert Media team is best equipped to create more leads and generate more sales for your business through social media marketing and advertising.',
//     images: [
//       {
//         url:
//           'https://www.iffertmedia.com/static/ee7180b9430b423ddc2fdbdfe2f76f3f/0dadc/IFFERT-MEDIA.png',
//         alt: 'Iffert Media',
//       },
//     ],
//     type: 'website',
//     site_name: 'Iffert Media | About Iffert Media',
//   }}
//   twitter={{
//     cardType: 'summary_large_image',
//     title: 'Iffert Media | Social Media Marketing & Advertising',
//     site: 'https://www.iffertmedia.com',
//     images: [
//       {
//         url:
//           'https://www.iffertmedia.com/static/ee7180b9430b423ddc2fdbdfe2f76f3f/0dadc/IFFERT-MEDIA.png',
//         alt: 'Iffert Media',
//       },
//     ],
//     description:
//       'Learn why the Iffert Media team is best equipped to create more leads and generate more sales for your business through social media marketing and advertising.',
//   }}
// />
